import {Layout} from "../../components/Layout"
import {Typography} from "@material-ui/core"
import styled from "@emotion/styled"
import {useEffect, useState} from "react"
import {useInterval} from "../../hooks/useInterval"

const KSTypo = styled(Typography)({
  fontWeight: "bold",
  color: "#FFFFFFB0"
})
const WithBorder = styled.span(({theme}) => ({
  color: "white",
  borderBottom: "solid 5px",
  marginBottom: "0.2em",
  display: "inline-block",
  lineHeight: "1em",
  borderColor: theme.palette.secondary.main
}))

const LiveKickstarterStats = () => {
  const [ksStats, setKsStats] = useState({backers: 0, percent: 0, toNext: 0})
  const fetchKsStats = () => fetch("https://data.recalbox.com/kickstarter/")
    .then(result => result.json()
      .then((json: { project: { backers_count: number, pledged: string } }) => {
        const pledge = parseInt(json.project.pledged)
        const percent = Math.round(100 * pledge / 4200)
        const toNext = 80000 - pledge
        setKsStats({toNext, percent, backers: json.project.backers_count})
      }))
  useInterval(() => {
    fetchKsStats()
  }, 100000)

  useEffect(() => {
    fetchKsStats()
  }, [])

  return <Layout title={""} noNav hideCookieConsent>
    <KSTypo variant={"h2"}>KICKSTARTER</KSTypo>
    <KSTypo variant={"h1"}>LIVE STATS:</KSTypo>
    <KSTypo variant={"h1"}><WithBorder>{ksStats.backers}</WithBorder> BACKERS</KSTypo>
    <KSTypo variant={"h1"}><WithBorder>{ksStats.percent}%</WithBorder> FUNDED</KSTypo>
    {ksStats.toNext > 0 ?
    <KSTypo variant={"h1"}><WithBorder>{ksStats.toNext}€</WithBorder> to next stretch goal!</KSTypo>
      :
      <KSTypo variant={"h1"}><WithBorder>All stretch goals reached!</WithBorder></KSTypo>

    }
  </Layout>
}
export default LiveKickstarterStats
